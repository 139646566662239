import { Container } from "@chakra-ui/react";
import * as React from "react";
import Article from "../../components/article";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import Seo from "../../components/seo";
import { translations } from "../../translations";
import type { Translation } from "../../types/main";

const t: Translation = {
	language: "se",
	translations: translations.se,
};

const Page = () => (
	<Layout>
		<Seo title="Flowby - Cut your next line" lang="sv" />
		<Navbar t={t} />
		<Container maxW="5xl" py={12}>
			<Article />
		</Container>
		<Container maxW="8xl" py={12}>
			<Footer t={t} />
		</Container>
	</Layout>
);

export default Page;
