import {
	Box,
	Button,
	Heading,
	Link,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Article() {
	return (
		<Stack as={Box} textAlign={"center"} spacing={{ base: 5, md: 8 }}>
			<Heading as="h2" fontSize="3xl">
				Digital lösning kan rädda kökaoset på Arlanda - men entreprenörerna får
				inget svar
			</Heading>
			<VStack spacing={5} textAlign="left">
				<Text fontSize="xl" color={"gray.700"}>
					Under våren och sommaren har det uppstått kökaos på många platser i
					landet. Bland andra hos Polisen på Arlanda där folk köar i dygn för
					att få göra provisoriska pass. Människor har stått fysiskt i kö i
					uppemot 41 timmar, enligt{" "}
					<b>
						<Link
							isExternal
							href="https://www.svt.se/nyheter/inrikes/41-timmar-i-ko-sen-fick-matilda-sitt-provisoriska-pass"
						>
							SVT
						</Link>
					</b>
					. Folk har tältat och sovit under bar himmel. De är arga, besvikna och
					har till och med svimmat. Det har pratats om “systemkollaps” av
					centrala och nödvändiga funktioner i samhället.
				</Text>
				<StaticImage
					loading="eager"
					src="../images/article/arlanda-polisen-queue-2.jpg"
					width={800}
					formats={["auto", "webp", "avif"]}
					alt="Arlanda polisen"
					objectFit="cover"
				/>
				<Text fontSize="xl" color={"gray.700"}>
					<i>Bild från kösituationen hos Arlandapolisen.</i>
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Flowby är ett nytt techbolag som erbjuder en digital kötjänst där
					kunderna köar i mobilen, istället för fysiskt. Du kan då röra sig
					fritt och uträtta andra ärenden under tiden i kön och notifieras när
					det snart är din tur. Som serviceinrättning kan man komma igång med
					att börja använda Flowby på fem minuter.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Flowby blev kontaktade av arga köare som ville att de skulle ta tag i
					situationen. Grundarna, Anna och Susanne, hade redan försökt få tag i
					Polisen för att uppmärksamma dem på deras lösning, men förgäves. De
					åkte då ut till Arlanda en tidig tisdagsmorgon i juni och pratade med
					köarna på plats.
				</Text>

				<StaticImage
					loading="eager"
					src="../images/article/message-to-flowby-about-queue-chaos.jpg"
					formats={["auto", "webp", "avif"]}
					alt="Message to flowby about queue chaos"
					objectFit="cover"
				/>
				<Text fontSize="xl" color={"gray.700"}>
					<i>Ett meddelande som Flowby mottog.</i>
				</Text>

				<StaticImage
					loading="eager"
					src="../images/article/arlanda-polisen-queue.jpg"
					width={800}
					formats={["auto", "webp", "avif"]}
					alt="Arlanda Polisen Queue"
					objectFit="cover"
				/>
				<Text fontSize="xl" color={"gray.700"}>
					<i>
						Flowbys grundare Anna Leijon och Susanne Tedsjö på plats utanför
						Arlandapolisen.
					</i>
				</Text>

				<Text fontSize="xl" color={"gray.700"}>
					Det visade sig att alla på plats skulle föredra ett mobilt kösystem
					framför ett fysiskt.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					<i>
						- "Vi upplevde en stor frustration bland de köare som vi pratade med
						ute på Arlanda. Det är naturligt - ingen vill stå i en kö och bara
						vänta. Vi har en lösning på det här problemet och vi hoppas att
						Polisen och andra serviceinrättningarna i samhället inser att det är
						fullkomligt onödigt att utsätta sina besökare för fysiska köer. Det
						är tråkigt när vi har utvecklat en lösning på ett problem som
						konsumenterna skriker efter, men som beslutsfattarna ignorerar."
					</i>
					, säger{" "}
					<b>
						<Link isExternal href="https://annaleijon.se/">
							Anna Leijon
						</Link>
					</b>
					, CPO på Flowby.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Flowby används redan idag framgångsrikt på exempelvis Mediamarkt, HiFi
					Klubben, Dormy, Decathlon och många andra ställen där det också ofta
					uppstår kö, särskilt under vissa tider.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					<i>
						- "Trots att både samhälls- och teknikutvecklingen har gjort enorma
						framsteg de senaste decennierna har sättet vi köar på stått still.
						Vi tycker inte vi ska behöva köa fysiskt år 2022 och bestämde oss
						för att göra någonting åt det. Det finns också andra fördelar för
						butiker och serviceinrättningar som inför vårt system eftersom
						kunderna tittar på andra varor och handlar mer istället för att stå
						stilla i en kö. Alla vinner på det!"
					</i>
					, säger Susanne Tedsjö, CEO på Flowby.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Flowbys hypotes är att ingen modern människa kommer vilja köa fysiskt
					år 2022. Därför bestämde de sig för att göra någonting åt det och
					började bygga deras nuvarande lösning för cirka ett år sedan. De finns
					redan hos åtta olika butiker och serviceinrättningar.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Nu är det upp till Polisen, övriga butiker och serviceinrättningar att
					hörsamma konsumenternas önskan om att inte behöva köa fysiskt längre.
					Fortsättning följer.
				</Text>

				<StaticImage
					loading="eager"
					src="../images/article/message-polisen-website.jpg"
					formats={["auto", "webp", "avif"]}
					alt="Message on polisens"
					objectFit="cover"
				/>
				<Text fontSize="xl" color={"gray.700"}>
					<i>Polisens egna officiella meddelande från deras hemsida.</i>
				</Text>
			</VStack>
		</Stack>
	);
}
